import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

function Loading(props) {
  return props.isLoading == true ? (
    <LoadingWrapper>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    </LoadingWrapper>
  ) : (
    <>{props.children}</>
  )
}

const LoadingWrapper = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

Loading.propTypes = {
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.any.isRequired,
}

export default Loading
