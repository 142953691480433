import { activateAccount, deactivateSubscription } from "helpers/api"
import { userHasLicense } from "helpers/license"
import { FaCheck } from "react-icons/fa"
import { RiCloseFill } from "react-icons/ri"
import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

const StandardPlanCard = ({ setLoading, t }) => {
  const currentUser = JSON.parse(localStorage.getItem("authUser"))
  const activeLicense =
    !userHasLicense("business") && userHasLicense("standard")

  const handleSubmit = () => {
    setLoading(true)
    if (userHasLicense("business")) deactivateSubscription()
    else activateAccount(currentUser?.username)
  }

  return (
    <section>
      <h2>Standard Account</h2>
      <div className="product">
        <div className="price">
          <h3>
            <span>0,00€</span>
          </h3>
          <h5>billed monthly</h5>
        </div>

        <div className="description">
          <div className="line">
            <p>
              <FaCheck className="icon" />
              {t("No app required!")}
            </p>
          </div>
          <div className="line">
            <p>
              <FaCheck className="icon" />
              {t("Download Contact Card")}
            </p>
          </div>
          <div className="line">
            <p>
              <FaCheck className="icon" />

              {t("Unlimited scans")}
            </p>
          </div>
          <div className="line">
            <p>
              <RiCloseFill className="icon-error" />{" "}
              {t("Upload Profile Images and Logos")}
            </p>
          </div>
          <div className="line">
            <p>
              <RiCloseFill className="icon-error" /> {t("Edit all fields")}
            </p>
          </div>
          <div className="line">
            <p>
              <RiCloseFill className="icon-error" /> {t("Upload companies")}
            </p>
          </div>
          <div className="line">
            <p>
              <RiCloseFill className="icon-error" />
              {t("Upload skills")}
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} method="POST">
        <button
          className="checkout-button"
          type="submit"
          disabled={activeLicense ? "disabled" : ""}
        >
          {activeLicense ? "current" : "select"}
        </button>
      </form>
    </section>
  )
}

StandardPlanCard.propTypes = {
  setLoading: PropTypes.any.isRequired,
}

export default withTranslation()(StandardPlanCard)
