import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = props => {
  const [username, setusername] = useState("Admin")
  const [email, setEmail] = useState("Admin")
  const [imageURL, setImageURL] = useState("./default-avatar.png")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setusername(obj.username)
      setEmail(obj.email)
      if (obj.image) setImageURL(obj.image)
    }
  }, [])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{props.t("Welcome Back !")}</h5>
                <p>{props.t("x-ACT.me")}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="8">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={imageURL}
                  style={{ objectFit: "cover" }}
                  alt=""
                  className="avatar-md rounded-circle img-thumbnail"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{username}</h5>
              <p className="text-muted mb-0">{email}</p>
            </Col>

            <Col sm="4">
              <div className="pt-4">
                <div className="mt-4 d-flex justify-content-end">
                  <Link to="/mypage" className="btn btn-primary btn-sm">
                    {props.t("View Profile")}
                    <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
