import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "assets/images/logo-sm-light.png"
import { forgotPassword } from "helpers/api"
import Loading from "pages/Helpers/Loading"

const ForgetPasswordPage = () => {
  const [successMsg, setSuccessMsg] = useState()
  const [errorMsg, setErrorMsg] = useState()
  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Bitte E-Mail angeben"),
    }),
    onSubmit: values => {
      forgotPassword(values.email, setSuccessMsg, setLoading, setErrorMsg)
    },
  })

  return (
    <React.Fragment>
      <Loading isLoading={loading}>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-softbg-soft-primary opacity-75">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Willkommen zurück !</h5>
                          <p></p>
                        </div>
                      </Col>
                      <Col
                        className="col-5 align-self-end"
                        style={{ zIndex: 99 }}
                      >
                        <img
                          src={profile}
                          style={{ zIndex: "200" }}
                          alt=""
                          className="img-fluid"
                        />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="75"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {errorMsg && errorMsg ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {errorMsg}
                        </Alert>
                      ) : null}
                      {successMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {successMsg}
                        </Alert>
                      ) : null}

                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">E-Mail</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="E-Mail angeben"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Zurücksetzen
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    <Link
                      to="login"
                      className="font-weight-medium text-primary"
                    >
                      Zurück zum Login
                    </Link>{" "}
                  </p>
                  <p>© {new Date().getFullYear()} x-ACT.me</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Loading>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default ForgetPasswordPage
