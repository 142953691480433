import { userHasLicense } from "helpers/license"
import { FaCheck } from "react-icons/fa"
import React from "react"
import PropTypes from "prop-types"
import { API_BaseURL } from "helpers/constants"
import { withTranslation } from "react-i18next"

const BusinessPlanCard = props => {
  const currentUserEmail = JSON.parse(localStorage.getItem("authUser"))?.email
  const activeLicense = userHasLicense("business")

  return (
    <section>
      <h2>Business Account</h2>
      <div className="product">
        <div className="price">
          <h3>
            <span>0,99 €</span>
          </h3>
          <h5>{props.t("billed monthly")}</h5>
        </div>
        <div className="description">
          <div className="line">
            <p>
              <FaCheck className="icon" />
              {props.t("No app required!")}
            </p>
          </div>
          <div className="line">
            <p>
              <FaCheck className="icon" />
              {props.t("Download Contact Card")}
            </p>
          </div>
          <div className="line">
            <p>
              <FaCheck className="icon" />

              {props.t("Unlimited scans")}
            </p>
          </div>
          <div className="line">
            <p>
              <FaCheck className="icon" />
              {props.t("Upload Profile Images and Logos")}
            </p>
          </div>
          <div className="line">
            <p>
              <FaCheck className="icon" />
              {props.t("Edit all fields")}
            </p>
          </div>
          <div className="line">
            <p>
              <FaCheck className="icon" />
              {props.t("Upload companies")}
            </p>
          </div>
          <div className="line">
            <p>
              <FaCheck className="icon" />
              {props.t("Upload skills")}
            </p>
          </div>
        </div>
      </div>
      <form
        action={
          API_BaseURL +
          "/stripe/create-plan-checkout-session/" +
          currentUserEmail
        }
        method="POST"
      >
        <button
          className="checkout-button"
          type="submit"
          disabled={activeLicense ? "disabled" : ""}
        >
          {activeLicense ? props.t("current") : props.t("select")}
        </button>
      </form>
    </section>
  )
}

export default withTranslation()(BusinessPlanCard)
