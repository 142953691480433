import React, { useEffect, useState, useRef } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
  Alert,
} from "reactstrap"

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "components/Common/DeleteModal"
import { getMyAccountSync, update } from "helpers/api"
import Loading from "pages/Helpers/Loading"
import styled from "styled-components"
import { userHasLicense } from "helpers/license"
import { withTranslation } from "react-i18next"

const Skills = props => {
  const [skills, setSkills] = useState([])
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const getSkills = async () => {
    setLoading(true)
    const currentUser = await getMyAccountSync()

    const tmp = []
    if (!currentUser.skills) currentUser.skills = []
    currentUser.skills.forEach((skill, index) => tmp.push({ id: index, skill }))
    setSkills(tmp)
    setUser(currentUser)
    setLoading(false)
  }

  useEffect(() => {
    if (!userHasLicense("business")) window.location.href = "/invalidlicense"
    getSkills()
  }, [])

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [skill, setSkill] = useState(null)

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: skill ?? {},
    validationSchema: Yup.object({}),
    onSubmit: async values => {
      if (isEdit) {
        setLoading(true)
        let tmp = skills
        tmp[values.id] = values

        await update(
          { ...user, skills: tmp.map(item => item.skill) },
          setError,
          () => {},
          setSuccess
        )
        validation.resetForm()
        toggle()
      } else {
        if (skills.length > 0) values.id = skills[skills.length - 1].id + 1
        else values.id = 0

        await update(
          { ...user, skills: [...user.skills, values.skill] },
          setError,
          () => {},
          setSuccess
        )
        validation.resetForm()
        toggle()
      }

      getSkills()
    },
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: skills.length, // replace later with size(orders),
    custom: true,
  }

  const skillsColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "skill",
      text: props.t("Skill"),
      sort: true,
      width: "80",
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: props.t("Action"),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, skill) => (
        <UncontrolledDropdown direction="left">
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#" onClick={() => handleSkillClick(skill)}>
              <i className="fas fa-pencil-alt text-success me-1" />
              {props.t("edit")}
            </DropdownItem>
            <DropdownItem href="#" onClick={() => onClickDelete(skill)}>
              <i className="fas fa-trash-alt text-danger me-1" />{" "}
              {props.t("delete")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      width: "20",
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setSkill(null)
    } else {
      setModal(true)
    }
  }

  const handleSkillClick = skill => {
    setSkill(skill)
    setIsEdit(true)
    toggle()
  }

  var node = useRef()

  //delete skill
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = skill => {
    setSkill(skill)
    setDeleteModal(true)
  }

  const handleDeleteskill = async () => {
    setLoading(true)
    const tmp = skills.filter(element => element.id != skill.id)
    await update(
      { ...user, skills: tmp.map(item => item.skill) },
      setError,
      () => {},
      setSuccess
    )
    setSkills(tmp)
    validation.resetForm()
    setDeleteModal(false)
    getSkills()
  }

  const { SearchBar } = Search

  const handleskillClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      <Loading isLoading={loading}>
        <>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteskill}
            onCloseClick={() => setDeleteModal(false)}
          />
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="x-ACT.me" breadcrumbItem="Skills" />
              {error && error ? (
                <Alert color="danger">{props.t(error)}</Alert>
              ) : null}
              {success && success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={skillsColumns}
                        data={skills}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={skills || []}
                            columns={skillsColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          placeholder={props.t("Search")}
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleskillClicks}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        {props.t("New Skill")}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        keyField="id"
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>
                                    <Modal isOpen={modal} toggle={toggle}>
                                      <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit
                                          ? props.t("Edit Skill")
                                          : props.t("Add Skill")}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Form
                                          onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                          }}
                                        >
                                          <Row form>
                                            <Col className="col-12">
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Skill")}
                                                </Label>
                                                <Input
                                                  name="skill"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.skill ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched.skill &&
                                                    validation.errors.skill
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.skill &&
                                                validation.errors.skill ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.skill}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-skill"
                                                >
                                                  {props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </ModalBody>
                                    </Modal>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      </Loading>
    </React.Fragment>
  )
}

const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;

  .overlay {
    background: black;
    opacity: 0.7;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    display: none;
    border-radius: 50%;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: white;
    display: none;
    pointer-events: none;
  }

  &:hover {
    svg {
      display: block;
    }
    .overlay {
      display: flex;
    }
  }
`

export default withTranslation()(Skills)
