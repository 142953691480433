import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Pricing from "pages/Pricing"
import ComparePlans from "pages/ComparePlans"
import Details from "pages/Details"
import Companies from "pages/UserProfile/Companies"
import Skills from "pages/UserProfile/Skills"
import Settings from "pages/Settings"
import Pages404 from "pages/Utility/pages-404"
import Activation from "pages/Authentication/Activation"
import InvalidLicense from "pages/Utility/page-invalid-license"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard }, // Entferne die JSX-Syntax
  { path: "/profile", component: UserProfile },
  { path: "/companies", component: Companies },
  { path: "/skills", component: Skills },
  { path: "/pricing", component: Pricing },
  { path: "/comparePlans", component: ComparePlans },
  { path: "/settings", component: Settings },
  { path: "/mypage", component: Details },
  { path: "/invalidlicense", component: InvalidLicense },
  { path: "/", exact: true, component: () => <Navigate to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login }, // Entferne die JSX-Syntax
  { path: "/activation/:id", component: Activation },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/details/:id", component: Details },
  { path: "/notfound", component: Pages404 },
]

export { publicRoutes, authProtectedRoutes }
