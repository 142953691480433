import React, { useEffect, useState, useRef } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
  Alert,
} from "reactstrap"

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "components/Common/DeleteModal"
import { getMyAccountSync, update, uploadImage } from "helpers/api"
import Loading from "pages/Helpers/Loading"
import styled from "styled-components"
import { FaEdit } from "react-icons/fa"
import { userHasLicense } from "helpers/license"
import { withTranslation } from "react-i18next"
import defaultCompanyAvatar from "../../../assets/images/default-company.png"

const Companies = props => {
  const [companies, setCompanies] = useState([])
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [companiesList, setCompaniesList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [company, setCompany] = useState(null)
  const [imgURL, setImgURL] = useState()
  const fileInput = useRef()
  var node = useRef()

  const getCompanies = async () => {
    setLoading(true)
    const currentUser = await getMyAccountSync()
    if (!currentUser.companies) currentUser.companies = []
    currentUser.companies.forEach((company, index) => (company.id = index))
    setCompanies(currentUser.companies)
    setUser(currentUser)
    setLoading(false)
  }

  useEffect(() => {
    if (!userHasLicense("business")) window.location.href = "/invalidlicense"
    getCompanies()
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: company ?? {},
    validationSchema: Yup.object({
      name: Yup.string().required("Please add company name"),
    }),
    onSubmit: values => {
      if (isEdit) {
        setLoading(true)
        let tmp = companies
        values.logo = imgURL
        tmp[values.id] = values
        update(
          { ...user, companies: companies },
          setError,
          setLoading,
          setSuccess
        )
        setCompanies(tmp)
        toggle()
        validation.resetForm()
      } else {
        if (companies.length > 0)
          values.id = companies[companies.length - 1].id + 1
        else values.id = 0
        values.logo = imgURL
        setCompanies([...companies, values])
        update(
          { ...user, companies: [...companies, values] },
          setError,
          setLoading,
          setSuccess
        )
        toggle()
        validation.resetForm()
      }
    },
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: companies.length, // replace later with size(orders),
    custom: true,
  }

  const CompaniesColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "field",
      text: props.t("Field"),
      sort: true,
    },
    {
      dataField: "position",
      text: props.t("Position"),
      sort: true,
    },
    {
      text: props.t("Contact"),
      dataField: "phoneNr",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.phoneNr}</p>
          <p className="mb-0">{row.email}</p>
        </>
      ),
    },
    {
      dataField: "address",
      text: props.t("Address"),
      sort: true,
    },
    {
      dataField: "postcode",
      text: props.t("City"),
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.postcode ?? "" + " " + (row.city ?? "")}</p>
        </>
      ),
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: props.t("Action"),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, company) => (
        <UncontrolledDropdown direction="left">
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#" onClick={() => handleCompanyClick(company)}>
              <i className="fas fa-pencil-alt text-success me-1" />{" "}
              {props.t("edit")}
            </DropdownItem>
            <DropdownItem href="#" onClick={() => onClickDelete(company)}>
              <i className="fas fa-trash-alt text-danger me-1" />{" "}
              {props.t("delete")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCompany(null)
    } else {
      setModal(true)
    }
  }

  const handleCompanyClick = company => {
    setCompany(company)
    setImgURL(company.logo)
    setIsEdit(true)
    toggle()
  }

  const onClickDelete = company => {
    setCompany(company)
    setDeleteModal(true)
  }

  const handleDeleteCompany = () => {
    if (company) {
      setLoading(true)
      const tmp = companies.filter(element => element.id != company.id)
      update({ ...user, companies: tmp }, setError, setLoading, setSuccess)
      setCompanies(tmp)
      validation.resetForm()
      setDeleteModal(false)
    } else console.log("error: not company selected")
  }

  const { SearchBar } = Search

  const handleTableChange = (type, { page, searchText }) => {
    setCompaniesList(
      companies.filter(company =>
        Object.keys(company).some(key =>
          company[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const handleAddCompanyClick = () => {
    setCompaniesList("")
    setIsEdit(false)
    toggle()
  }

  async function handleImgChange(event) {
    setLoading(true)
    const file = event.target.files[0]

    const url = await uploadImage(file)
    setImgURL(url)

    setLoading(false)
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      <Loading isLoading={loading}>
        <>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteCompany}
            onCloseClick={() => setDeleteModal(false)}
          />
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title="x-ACT.me"
                breadcrumbItem={props.t("Companies")}
              />
              {error && error ? (
                <Alert color="danger">{props.t(error)}</Alert>
              ) : null}
              {success && success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={CompaniesColumns}
                        data={companies}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={companies || []}
                            columns={CompaniesColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          placeholder={props.t("Search")}
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleAddCompanyClick}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        {props.t("New Company")}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        keyField="id"
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>
                                    <Modal isOpen={modal} toggle={toggle}>
                                      <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit
                                          ? props.t("Edit Company")
                                          : props.t("Add Company")}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Form
                                          onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                          }}
                                        >
                                          <Row form>
                                            <Col className="col-12">
                                              <ImageContainer>
                                                <input
                                                  style={{ display: "none" }}
                                                  type="file"
                                                  accept="image/*"
                                                  onChange={handleImgChange}
                                                  ref={file =>
                                                    (fileInput.current = file)
                                                  }
                                                />
                                                <img
                                                  src={
                                                    imgURL ??
                                                    defaultCompanyAvatar
                                                  }
                                                  style={{ objectFit: "cover" }}
                                                  alt="avatar"
                                                  className="avatar-lg rounded-circle img-thumbnail"
                                                  onClick={() =>
                                                    fileInput.current.click()
                                                  }
                                                />
                                                <div
                                                  className="overlay"
                                                  onClick={() =>
                                                    fileInput.current.click()
                                                  }
                                                ></div>
                                                <FaEdit />
                                              </ImageContainer>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Name")}
                                                </Label>
                                                <Input
                                                  name="name"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.name || ""
                                                  }
                                                  invalid={
                                                    validation.touched.name &&
                                                    validation.errors.name
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.name &&
                                                validation.errors.name ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.name}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Field")}
                                                </Label>
                                                <Input
                                                  name="field"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.field ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched.field &&
                                                    validation.errors.field
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.field &&
                                                validation.errors.field ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.field}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Position")}
                                                </Label>
                                                <Input
                                                  name="position"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .position || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .position &&
                                                    validation.errors.position
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.position &&
                                                validation.errors.position ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.position}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Website")}
                                                </Label>
                                                <Input
                                                  name="website"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.website ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .website &&
                                                    validation.errors.website
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.website &&
                                                validation.errors.website ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.website}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Email")}
                                                </Label>
                                                <Input
                                                  name="email"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.email ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched.email &&
                                                    validation.errors.email
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.email &&
                                                validation.errors.email ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.email}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Phone Number")}
                                                </Label>
                                                <Input
                                                  name="phoneNr"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.phoneNr ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .phoneNr &&
                                                    validation.errors.phoneNr
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.phoneNr &&
                                                validation.errors.phoneNr ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.phoneNr}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Mobile Number")}
                                                </Label>
                                                <Input
                                                  name="mobileNr"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .mobileNr || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .mobileNr &&
                                                    validation.errors.mobileNr
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.mobileNr &&
                                                validation.errors.mobileNr ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.mobileNr}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Address")}
                                                </Label>
                                                <Input
                                                  name="address"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.address ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .address &&
                                                    validation.errors.address
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.address &&
                                                validation.errors.address ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.address}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Post code")}
                                                </Label>
                                                <Input
                                                  name="postcode"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .postcode || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .postcode &&
                                                    validation.errors.postcode
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.postcode &&
                                                validation.errors.postcode ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.postcode}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("City")}
                                                </Label>
                                                <Input
                                                  name="city"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.city || ""
                                                  }
                                                  invalid={
                                                    validation.touched.city &&
                                                    validation.errors.city
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.city &&
                                                validation.errors.city ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.city}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  {props.t("Country")}
                                                </Label>
                                                <Input
                                                  name="country"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.country ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .country &&
                                                    validation.errors.country
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.country &&
                                                validation.errors.country ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.country}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Facebook URL
                                                </Label>
                                                <Input
                                                  name="facebookURL"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .facebookURL || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .facebookURL &&
                                                    validation.errors
                                                      .facebookURL
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .facebookURL &&
                                                validation.errors
                                                  .facebookURL ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .facebookURL
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Instagram URL
                                                </Label>
                                                <Input
                                                  name="instagramURL"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .instagramURL || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .instagramURL &&
                                                    validation.errors
                                                      .instagramURL
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .instagramURL &&
                                                validation.errors
                                                  .instagramURL ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .instagramURL
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  LinkedIn URL
                                                </Label>
                                                <Input
                                                  name="linkedInURL"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .linkedInURL || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .linkedInURL &&
                                                    validation.errors
                                                      .linkedInURL
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .linkedInURL &&
                                                validation.errors
                                                  .linkedInURL ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .linkedInURL
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-company"
                                                >
                                                  {props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </ModalBody>
                                    </Modal>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      </Loading>
    </React.Fragment>
  )
}

const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;

  .overlay {
    background: black;
    opacity: 0.7;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    display: none;
    border-radius: 50%;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: white;
    display: none;
    pointer-events: none;
  }

  &:hover {
    svg {
      display: block;
    }
    .overlay {
      display: flex;
    }
  }
`

export default withTranslation()(Companies)
