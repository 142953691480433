import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "./i18n"
import { Provider } from "react-redux"

import store from "./store"

const container = document.getElementById("root")

const root = ReactDOM.createRoot(container)

const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

root.render(app)

serviceWorker.unregister()
