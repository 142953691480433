import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"

//import Charts

// Pages Components
import WelcomeComp from "./WelcomeComp"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import Chart from "./Chart"
import Loading from "pages/Helpers/Loading"
import { getScans } from "helpers/api"

const Dashboard = props => {
  const [loading, setLoading] = useState(true)
  const [values, setValues] = useState([])

  useEffect(() => {
    getScans(setLoading, setValues)
  }, [])

  return (
    <React.Fragment>
      <Loading isLoading={loading}>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("x-ACT.me")}
              breadcrumbItem={props.t("Dashboard")}
            />

            <Row>
              <Col xl="4">
                <WelcomeComp t={props.t} />
              </Col>
              <Col xl="8">
                {values && <Chart t={props.t} values={values} />}
              </Col>
            </Row>
          </Container>
        </div>
      </Loading>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
