import React, { useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loading from "pages/Helpers/Loading"
import {
  changeEmail,
  changePassword,
  changeUsername,
  deleteAccount,
} from "helpers/api"
import { withTranslation } from "react-i18next"
import DeleteModal from "components/Common/DeleteModal"

const Settings = props => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [deleteModal, setDeleteModal] = useState()
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Please Enter Your Email")),
    }),
    onSubmit: values => {
      setLoading(true)
      changeEmail(values.email, setSuccess, setLoading, setError)
    },
  })

  const validationUsername = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(props.t("Please Enter Your Username")),
    }),
    onSubmit: values => {
      setLoading(true)
      changeUsername(values.username, setSuccess, setLoading, setError)
    },
  })

  const handleDelete = () => {
    setLoading(true)
    deleteAccount(setError)
  }

  const validationPwd = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(props.t("Please Enter Your Password")),
      newPassword: Yup.string().required(
        props.t("Please Enter Your New Password")
      ),
    }),
    onSubmit: values => {
      setLoading(true)
      changePassword(
        values.password,
        values.newPassword,
        setSuccess,
        setLoading,
        setError
      )
    },
  })

  return (
    <React.Fragment>
      <Loading isLoading={loading}>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="x-ACT.me"
              breadcrumbItem={props.t("Settings")}
            />
            <Row>
              <Col lg="12">
                {error && error ? (
                  <Alert color="danger">{props.t(error)}</Alert>
                ) : null}
                {success ? (
                  <Alert color="success">{props.t(success)}</Alert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <h4 className="card-title">{props.t("Change Password")}</h4>
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validationPwd.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3 mt-3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                {props.t("Current Password")}
                              </Label>
                              <Input
                                name="password"
                                placeholder={props.t("Current Password")}
                                type="password"
                                className="form-control"
                                id="validationCustom01"
                                onChange={validationPwd.handleChange}
                                onBlur={validationPwd.handleBlur}
                                value={validationPwd.values.password || ""}
                                invalid={
                                  validationPwd.touched.password &&
                                  validationPwd.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validationPwd.touched.password &&
                              validationPwd.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validationPwd.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <Label htmlFor="validationCustom01">
                              {props.t("New Password")}
                            </Label>
                            <Input
                              name="newPassword"
                              placeholder={props.t("New Password")}
                              type="password"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validationPwd.handleChange}
                              onBlur={validationPwd.handleBlur}
                              value={validationPwd.values.newPassword || ""}
                              invalid={
                                validationPwd.touched.newPassword &&
                                validationPwd.errors.newPassword
                                  ? true
                                  : false
                              }
                            />
                            {validationPwd.touched.newPassword &&
                            validationPwd.errors.newPassword ? (
                              <FormFeedback type="invalid">
                                {validationPwd.errors.newPassword}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Button color="primary" type="submit">
                        {props.t("Submit")}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <h4 className="card-title">
                      {props.t("Change Email Address")}
                    </h4>
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3 mt-3">
                            <Label htmlFor="validationCustom01">
                              {props.t("New Email Address")}
                            </Label>
                            <Input
                              name="email"
                              placeholder="Email"
                              type="email"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Button color="primary" type="submit">
                        {props.t("Submit")}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <h4 className="card-title">{props.t("Change Username")}</h4>
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validationUsername.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3 mt-3">
                            <Label htmlFor="validationCustom01">
                              {props.t("New Username")}
                            </Label>
                            <Input
                              name="username"
                              placeholder={props.t("Username")}
                              type="username"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validationUsername.handleChange}
                              onBlur={validationUsername.handleBlur}
                              value={validationUsername.values.username || ""}
                              invalid={
                                validationUsername.touched.username &&
                                validationUsername.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validationUsername.touched.username &&
                            validationUsername.errors.username ? (
                              <FormFeedback type="invalid">
                                {validationUsername.errors.username}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Button color="primary" type="submit">
                        {props.t("Submit")}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        setDeleteModal(true)
                        return false
                      }}
                    >
                      <Button color="danger" type="submit">
                        {props.t("Delete Account")}
                      </Button>
                      <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDelete}
                        onCloseClick={() => setDeleteModal(false)}
                      />
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Loading>
    </React.Fragment>
  )
}

export default withTranslation()(Settings)
