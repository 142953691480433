export const Colors = {
  // primaryColor: "#6e8dbf",
  primaryColor: "#364C63",
  secondaryColor: "#F3B340",
  errorColor: "#BE2F29",
  textColor: "#424445",
  footerColor: "#757575",
  headings: "#525252",
  userColor: "#525888",
  warningColor: "#d9534f",
}
