import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import "react-datepicker/dist/react-datepicker.css"
import "@vtaits/react-color-picker/dist/index.css"
import { FaEdit } from "react-icons/fa"

//redux

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import defaultAvatar from "../../assets/images/default-avatar.png"
// actions
import { getMyAccount, update, uploadImage } from "helpers/api"
import Loading from "pages/Helpers/Loading"
import { TwitterPicker } from "react-color"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

const UserProfile = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(true)
  const [colorPicker, setColorPicker] = useState(false)
  const fileInput = useRef()

  useEffect(() => {
    getMyAccount(setUser, setLoading)
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: user,
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: values => {
      update(values, setError, setLoading, setSuccess)
      window.scrollTo(0, 0)
    },
  })

  async function handleImgChange(event) {
    setLoading(true)
    const file = event.target.files[0]

    if (file.size > 1000000)
      displayError("Image is too big. Please resize the image.")
    else {
      const url = await uploadImage(file)
      setUser({ ...user, image: url })
    }
    setLoading(false)
  }

  function displayError(message) {
    setError(message)
    setTimeout(() => {
      setError()
    }, 3000)
  }

  function handleColorChange(color) {
    setUser({ ...user, color: color.hex })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Loading isLoading={loading}>
            <>
              {!loading && (
                <>
                  {/* Render Breadcrumb */}
                  <Breadcrumb
                    title="x-ACT.me"
                    breadcrumbItem={props.t("Profile")}
                  />
                  <Row>
                    <Col lg="12">
                      {error && error ? (
                        <Alert color="danger">{props.t(error)}</Alert>
                      ) : null}
                      {success ? (
                        <Alert color="success">{props.t(success)}</Alert>
                      ) : null}

                      <Card>
                        <CardBody>
                          <div className="d-flex align-items-center">
                            <div className="ms-3">
                              {user.licenses?.includes("business") ? (
                                <ImageContainer>
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImgChange}
                                    ref={file => (fileInput.current = file)}
                                  />
                                  <img
                                    src={user.image ?? defaultAvatar}
                                    style={{ objectFit: "cover" }}
                                    alt="avatar"
                                    className="avatar-lg rounded-circle img-thumbnail"
                                    onClick={() => fileInput.current.click()}
                                  />
                                  <div
                                    className="overlay"
                                    onClick={() => fileInput.current.click()}
                                  ></div>
                                  <FaEdit />
                                </ImageContainer>
                              ) : (
                                <img
                                  src={defaultAvatar}
                                  style={{ objectFit: "cover" }}
                                  alt=""
                                  className="avatar-lg rounded-circle img-thumbnail"
                                />
                              )}
                            </div>
                            <div className="flex-grow-1 align-self-center mx-4">
                              <div className="text-muted">
                                <h5>
                                  {(user.firstname ?? "") +
                                    " " +
                                    (user.lastname ?? "")}
                                </h5>
                                <p className="mb-1">{user.email ?? ""}</p>
                              </div>
                            </div>
                            {!user.licenses?.includes("business") && (
                              <Link
                                to="/pricing"
                                style={{ display: "block", marginRight: "5%" }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-secondary w-md "
                                >
                                  {props.t("Upgrade Now")}
                                </button>
                              </Link>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <Form
                                className="form-horizontal"
                                onSubmit={e => {
                                  e.preventDefault()
                                  validation.handleSubmit()
                                  return false
                                }}
                              >
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("First Name")}</Label>
                                      <Input
                                        name="firstname"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        type="text"
                                        maxLength="10"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.firstname || ""
                                        }
                                        invalid={
                                          validation.touched.firstname &&
                                          validation.errors.firstname
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.firstname &&
                                      validation.errors.firstname ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.firstname}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("Last Name")}</Label>
                                      <Input
                                        name="lastname"
                                        maxLength="50"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.lastname || ""
                                        }
                                        invalid={
                                          validation.touched.lastname &&
                                          validation.errors.lastname
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.lastname &&
                                      validation.errors.lastname ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.lastname}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("Phone Number")}</Label>
                                      <Input
                                        name="phoneNr"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        type="text"
                                        maxLength="50"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.phoneNr || ""
                                        }
                                        invalid={
                                          validation.touched.phoneNr &&
                                          validation.errors.phoneNr
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.phoneNr &&
                                      validation.errors.phoneNr ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.phoneNr}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("Mobile Number")}</Label>
                                      <Input
                                        name="mobileNr"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter Mobile Number"
                                        type="text"
                                        maxLength="50"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.mobileNr || ""
                                        }
                                        invalid={
                                          validation.touched.mobileNr &&
                                          validation.errors.mobileNr
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? props.t(
                                                "Please upgrade to business license to edit this field"
                                              )
                                            : ""
                                        }
                                      />
                                      {validation.touched.mobileNr &&
                                      validation.errors.mobileNr ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.mobileNr}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("Job Title")}</Label>
                                      <Input
                                        name="jobtitle"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter Job Title"
                                        type="text"
                                        maxLength="50"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.jobtitle || ""
                                        }
                                        invalid={
                                          validation.touched.jobtitle &&
                                          validation.errors.jobtitle
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? props.t(
                                                "Please upgrade to business license to edit this field"
                                              )
                                            : ""
                                        }
                                      />
                                      {validation.touched.jobtitle &&
                                      validation.errors.jobtitle ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.jobtitle}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("Description")}</Label>
                                      <Input
                                        name="description"
                                        // value={name}
                                        className="form-control"
                                        maxLength="100"
                                        placeholder="Enter Description"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.description || ""
                                        }
                                        invalid={
                                          validation.touched.description &&
                                          validation.errors.description
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.description &&
                                      validation.errors.description ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.description}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>
                                        {props.t("Street and Number")}
                                      </Label>
                                      <Input
                                        name="street"
                                        // value={name}
                                        className="form-control"
                                        maxLength="50"
                                        placeholder="Enter Street and Number"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation?.values?.street || ""}
                                        invalid={
                                          validation.touched.street &&
                                          validation.errors.street
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.street &&
                                      validation.errors.street ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.street}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("Zip Code")}</Label>
                                      <Input
                                        name="zipCode"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter Zip Code"
                                        maxLength="50"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.zipCode || ""
                                        }
                                        invalid={
                                          validation.touched.zipCode &&
                                          validation.errors.zipCode
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.zipCode &&
                                      validation.errors.zipCode ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.zipCode}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("City")}</Label>
                                      <Input
                                        name="city"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter City"
                                        type="text"
                                        maxLength="50"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation?.values?.city || ""}
                                        invalid={
                                          validation.touched.city &&
                                          validation.errors.city
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.city &&
                                      validation.errors.city ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.city}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("Country")}</Label>
                                      <Input
                                        name="country"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter Country"
                                        type="text"
                                        maxLength="50"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.country || ""
                                        }
                                        invalid={
                                          validation.touched.country &&
                                          validation.errors.country
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.country &&
                                      validation.errors.country ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.country}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>Facebook URL</Label>
                                      <Input
                                        name="facebookURL"
                                        // value={name}
                                        className="form-control"
                                        maxLength="250"
                                        placeholder="Enter Facebook URL"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.facebookURL || ""
                                        }
                                        invalid={
                                          validation.touched.facebookURL &&
                                          validation.errors.facebookURL
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.facebookURL &&
                                      validation.errors.facebookURL ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.facebookURL}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>Instagram URL</Label>
                                      <Input
                                        name="instagramURL"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter Instagram URL"
                                        type="text"
                                        maxLength="250"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.instagramURL || ""
                                        }
                                        invalid={
                                          validation.touched.instagramURL &&
                                          validation.errors.instagramURL
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.instagramURL &&
                                      validation.errors.instagramURL ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.instagramURL}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>TikTok URL</Label>
                                      <Input
                                        name="tiktokURL"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter TikTok URL"
                                        type="text"
                                        maxLength="250"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.tiktokURL || ""
                                        }
                                        invalid={
                                          validation.touched.tiktokURL &&
                                          validation.errors.tiktokURL
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.tiktokURL &&
                                      validation.errors.tiktokURL ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.tiktokURL}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>Twitter URL</Label>
                                      <Input
                                        name="twitterURL"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter Twitter URL"
                                        type="text"
                                        maxLength="250"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.twitterURL || ""
                                        }
                                        invalid={
                                          validation.touched.twitterURL &&
                                          validation.errors.twitterURL
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.twitterURL &&
                                      validation.errors.twitterURL ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.twitterURL}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>LinkedIn URL</Label>
                                      <Input
                                        name="linkedInURL"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter TikTok URL"
                                        type="text"
                                        maxLength="250"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.linkedInURL || ""
                                        }
                                        invalid={
                                          validation.touched.linkedInURL &&
                                          validation.errors.linkedInURL
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.linkedInURL &&
                                      validation.errors.linkedInURL ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.linkedInURL}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>XING URL</Label>
                                      <Input
                                        name="xingURL"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter XING URL"
                                        type="text"
                                        maxLength="250"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.xingURL || ""
                                        }
                                        invalid={
                                          validation.touched.xingURL &&
                                          validation.errors.xingURL
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.xingURL &&
                                      validation.errors.xingURL ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.xingURL}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>Youtube URL</Label>
                                      <Input
                                        name="youtubeURL"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter Youtube URL"
                                        type="text"
                                        maxLength="250"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.youtubeURL || ""
                                        }
                                        invalid={
                                          validation.touched.youtubeURL &&
                                          validation.errors.youtubeURL
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.youtubeURL &&
                                      validation.errors.youtubeURL ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.youtubeURL}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>Custom URL</Label>
                                      <Input
                                        name="customURL"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter Custom URL"
                                        type="text"
                                        maxLength="250"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation?.values?.customURL || ""
                                        }
                                        invalid={
                                          validation.touched.customURL &&
                                          validation.errors.customURL
                                            ? true
                                            : false
                                        }
                                        disabled={
                                          !user.licenses?.includes("business")
                                            ? "disabled"
                                            : ""
                                        }
                                        title={
                                          !user.licenses?.includes("business")
                                            ? "Please upgrade to business license to edit this field"
                                            : ""
                                        }
                                      />
                                      {validation.touched.customURL &&
                                      validation.errors.customURL ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.customURL}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label>{props.t("Color")}</Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        value={user.color}
                                        onClick={() => {
                                          setColorPicker(!colorPicker)
                                        }}
                                        readOnly
                                      />
                                      {colorPicker ? (
                                        <TwitterPicker
                                          color={
                                            user.color ? user.color : undefined
                                          }
                                          onChangeComplete={handleColorChange}
                                        />
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>

                                {/* <div className="mb-3">
                                  <div className="form-check">
                                    <Input
                                      type="checkbox"
                                      className="form-check-Input"
                                      id="formrow-customCheck"
                                      onChange={e =>
                                        setUser({
                                          ...user,
                                          public: e.target.checked,
                                        })
                                      }
                                      defaultChecked={user.public}
                                    />
                                    <Label
                                      className="form-check-Label"
                                      htmlFor="formrow-customCheck"
                                    >
                                      {props.t("I want to be a public profile")}
                                    </Label>
                                  </div>
                                </div> */}
                                {!user.licenses?.includes("business") && (
                                  <div className="mb-3">
                                    <Label className="text-danger">
                                      {props.t(
                                        "Please upgrade to business license to edit all fields"
                                      )}
                                    </Label>
                                  </div>
                                )}
                                <div>
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-md"
                                  >
                                    {props.t("Update")}
                                  </button>
                                </div>
                              </Form>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )}
            </>
          </Loading>
        </Container>
      </div>
    </React.Fragment>
  )
}

const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;

  .overlay {
    background: black;
    opacity: 0.7;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    display: none;
    border-radius: 50%;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: white;
    display: none;
    pointer-events: none;
  }

  &:hover {
    svg {
      display: block;
    }
    .overlay {
      display: flex;
    }
  }
`

export default withTranslation()(UserProfile)
