import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const Chart = props => {
  const { values } = props

  const days = [
    props.t("Mo"),
    props.t("Tue"),
    props.t("Wed"),
    props.t("Thu"),
    props.t("Fri"),
    props.t("Sat"),
    props.t("Sun"),
  ]

  var daysSorted = []

  for (var i = 7; i >= 0; i--) {
    var newDate = new Date(new Date().setDate(new Date().getDate() - i))
    daysSorted.push(days[newDate.getDay()])
  }

  function getWeekStatistic() {
    let numOfScans = [0, 0, 0, 0, 0, 0, 0]
    const date = new Date()

    for (let i = 0; i < numOfScans.length; i++) {
      var results = values.filter(obj => {
        return new Date(obj.date).toDateString() === date.toDateString()
      })
      date.setDate(date.getDate() - 1)
      numOfScans[numOfScans.length - (i + 1)] = results?.length
    }

    return numOfScans
  }

  function getMonthTotal() {
    const currentDate = new Date()

    return values.filter(obj => {
      var date = new Date(obj.date)
      return (
        currentDate.getMonth() === date.getMonth() &&
        currentDate.getFullYear() === date.getFullYear()
      )
    }).length
  }

  function getYearTotal() {
    const currentDate = new Date()

    return values.filter(obj => {
      var date = new Date(obj.date)
      return currentDate.getFullYear() === date.getFullYear()
    }).length
  }

  const numOfScans = getWeekStatistic()
  const series = [
    {
      name: "scans",
      data: numOfScans,
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: daysSorted,
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-flex flex-wrap">
            <h5 className="card-title me-2">{props.t("Statistic")}</h5>
          </div>

          <Row className="text-center">
            <Col lg={4}>
              <div className="mt-4">
                <p className="text-muted mb-1">{props.t("Today")}</p>
                <h5>
                  {numOfScans && numOfScans.length > 0
                    ? numOfScans[numOfScans.length - 1]
                    : 0}
                </h5>
              </div>
            </Col>

            <Col lg={4}>
              <div className="mt-4">
                <p className="text-muted mb-1">{props.t("This Month")}</p>
                <h5>{getMonthTotal() ?? 0}</h5>
              </div>
            </Col>

            <Col lg={4}>
              <div className="mt-4">
                <p className="text-muted mb-1">{props.t("This Year")}</p>
                <h5>{getYearTotal() ?? 0}</h5>
              </div>
            </Col>
          </Row>

          <hr className="mb-4" />
          <div id="area-chart" dir="ltr">
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={350}
              className="apex-charts"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Chart
