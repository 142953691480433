import axios from "axios"
import FileResizer from "react-image-file-resizer"
import { API_BaseURL } from "./constants"

const instance = axios.create({ baseURL: API_BaseURL })

function reloggin() {
  localStorage.clear()
  window.location.href = "/login"
}

const getUserByID = async (id, setUser, setLoading, setTheme) => {
  try {
    const res = await instance.get(API_BaseURL + "/users/details/" + id)

    if (res.data.color) setTheme({ userColor: res.data.color })
    setUser(res.data)
    setLoading(false)
  } catch (error) {
    console.log(error)
    if (error.response?.status === 404)
      return (window.location.href = "/notfound")

    if (error.response?.status === 400)
      return (window.location.href = "/activation/" + id)

    if (error.response?.status === 403)
      return (window.location.href = "/invalidlicense")

    window.location.href = "/"
  }
}

const getMyAccount = async (setUser, setLoading, setTheme) => {
  try {
    const res = await instance.get(API_BaseURL + "/users/myaccount", {
      headers: {
        "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
      },
    })
    setUser(res.data)
    if (setTheme && res.data.color) setTheme({ userColor: res.data.color })

    setLoading(false)
  } catch (error) {
    if (error.response?.status === 401) return reloggin()

    if (error.response?.status === 404)
      return (window.location.href = "/notfound")

    if (error.response?.status === 403)
      return (window.location.href = "/pricing")

    console.log(error)
  }
}

const getMyAccountSync = async () => {
  try {
    const res = await instance.get(API_BaseURL + "/users/myaccount", {
      headers: {
        "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
      },
    })
    return res.data
  } catch (error) {
    if (error.response?.status === 401) return reloggin()

    if (error.response?.status === 404)
      return (window.location.href = "/notfound")

    if (error.response?.status === 403)
      return (window.location.href = "/pricing")

    console.log(error)
  }
}

const update = async (user, setError, setLoading, setSuccess) => {
  try {
    await instance.put(API_BaseURL + "/users/" + user.username, user, {
      headers: {
        "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
      },
    })
    setLoading(false)
    displayMessage(setSuccess, "Successfully updated!")
    setError()
  } catch (error) {
    console.log(error)
    if (error.response?.status === 401) reloggin()
    else {
      if (error.response?.data) displayMessage(setError, error.response.data)
      else displayMessage(setError, "An unexpected error happened!")
    }
  }
}

const getScans = async (setLoading, setData) => {
  try {
    const user = JSON.parse(localStorage.getItem("authUser"))

    const res = await instance.get(
      API_BaseURL + "/users/getscans/" + user?.username,
      {
        headers: {
          "x-auth-token": user?.token,
        },
      }
    )
    setData(res.data)
    setLoading(false)
  } catch (error) {
    console.log(error)
    if (error.response?.status === 401) return reloggin()

    if (error.response?.status === 404)
      return (window.location.href = "/notfound")

    if (error.response?.status === 403)
      return (window.location.href = "/invalidlicense")

    window.location.href = "/"
  }
}

const login = async (values, setError, setLoading, navigate) => {
  instance
    .post(API_BaseURL + "/users/login", values)
    .then(res => {
      localStorage.setItem("authUser", JSON.stringify(res.data))
      if (res.data.token !== undefined) navigate("/dashboard")
      else navigate("/pricing")
    })
    .catch(err => {
      console.log(err)
      if (err.response?.status === 403) {
        if (!err.response?.data) return displayError("Invalid Error happened")
        localStorage.setItem("authUser", JSON.stringify(err.response?.data))
        return (window.location.href = "/pricing")
      }

      displayError(err, setError, setLoading)
    })
}

const activate = async (
  id,
  email,
  password,
  setError,
  setLoading,
  setSuccess
) => {
  instance
    .post(API_BaseURL + "/users/activate", {
      id,
      email,
      password,
    })
    .then(() => {
      setLoading(false)
      setSuccess("Tag erfolgreich aktiviert!")
      setTimeout(() => {
        window.location.href = "/details/" + id
      }, 2500)
      return
    })
    .catch(err => {
      console.log(err)
      displayError(err, setError, setLoading)
      setLoading(false)
    })
}

const register = async (user, setLoading, setError) => {
  instance
    .post(API_BaseURL + "/users/register", user)
    .then(res => {
      localStorage.setItem("authUser", JSON.stringify(res.data))
      window.location.href = "/dashboard"
    })
    .catch(err => {
      console.log(err)
      displayError(err, setError, setLoading)
    })
}

const finalizePlanCheckout = async email => {
  instance
    .post(API_BaseURL + "/stripe/finalizePlanCheckout", {
      email,
    })
    .then(res => {
      if (res.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(res.data))
        window.location.href = "/mypage"
      } else window.location.href = "/"
    })
    .catch(err => {
      console.log(err)
      // window.location.href = '/';
    })
}

const deactivateSubscription = async () => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  instance
    .post(
      API_BaseURL + "/stripe/deactivateSubscription",
      {
        username: user.username,
      },
      {
        headers: {
          "x-auth-token": user?.token,
        },
      }
    )
    .then(res => {
      if (res.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(res.data))
        window.location.href = "/mypage"
      } else window.location.href = "/"
    })
    .catch(err => {
      console.log(err)
    })
}

const activateAccount = async username => {
  instance
    .post(
      API_BaseURL + "/users/activateaccount",
      {
        username,
      },
      {
        headers: {
          "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
        },
      }
    )
    .then(res => {
      if (res.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(res.data))
        window.location.href = "/mypage"
      } else window.location.href = "/"
    })
    .catch(err => {
      console.log(err)
      // window.location.href = '/';
    })
}

const forgotPassword = async (email, setSuccess, setIsLoading, setError) => {
  instance
    .post(API_BaseURL + "/users/forgotPassword", {
      email,
    })
    .then(() => {
      setSuccess("Bitte überprüfen Sie Ihr E-Mail Postfach.")
      setIsLoading(false)
    })
    .catch(err => {
      displayError(err, setError, setIsLoading)
    })
}

const resetPassword = async (
  token,
  password,
  setSuccess,
  setIsLoading,
  setError
) => {
  instance
    .post(API_BaseURL + "/users/resetPassword", {
      token,
      password,
    })
    .then(() => {
      setSuccess(true)
      setIsLoading(false)
    })
    .catch(err => {
      displayError(err, setError, setIsLoading)
    })
}

const changePassword = async (
  currentPassword,
  newPassword,
  setSuccess,
  setIsLoading,
  setError
) => {
  const user = JSON.parse(localStorage.getItem("authUser"))

  instance
    .post(
      API_BaseURL + "/users/changePassword",
      {
        newPassword,
        currentPassword,
        username: user.username,
      },
      {
        headers: {
          "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
        },
      }
    )
    .then(() => {
      setSuccess("Successfully changed Password")
      setError()
      setIsLoading(false)
    })
    .catch(err => {
      displayError(err, setError, setIsLoading)
    })
}

const changeEmail = async (email, setSuccess, setIsLoading, setError) => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  instance
    .post(
      API_BaseURL + "/users/changeEmail",
      {
        username: user.username,
        email,
      },
      {
        headers: {
          "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
        },
      }
    )
    .then(res => {
      if (res.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(res.data))
        setSuccess("Successfully changed Email Address!")
        setError()
        setIsLoading(false)
      } else window.location.href = "/"
    })
    .catch(err => {
      displayError(err, setError, setIsLoading)
    })
}

const changeUsername = async (
  newUsername,
  setSuccess,
  setIsLoading,
  setError
) => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  instance
    .post(
      API_BaseURL + "/users/changeUsername",
      {
        username: user.username,
        newUsername,
      },
      {
        headers: {
          "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
        },
      }
    )
    .then(res => {
      if (res.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(res.data))
        setSuccess("Successfully changed Username!")
        setError()
        setIsLoading(false)
      } else window.location.href = "/"
    })
    .catch(err => {
      displayError(err, setError, setIsLoading)
    })
}

const addItem = async (username, tagID, setSuccess, setIsLoading, setError) => {
  instance
    .post(
      API_BaseURL + "/tags",
      {
        username,
        tagID,
      },
      {
        headers: {
          "x-auth-token": JSON.parse(localStorage.getItem("authUser"))?.token,
        },
      }
    )
    .then(() => {
      setSuccess(true)
      setIsLoading(false)
    })
    .catch(err => {
      displayError(err, setError, setIsLoading)
    })
}

const uploadImage = async file => {
  const user = JSON.parse(localStorage.getItem("authUser"))

  FileResizer.imageFileResizer(
    file,
    300,
    300,
    "JPEG",
    100,
    0,
    uri => {
      console.log(uri)
    },
    "base64",
    200,
    200
  )

  let res = await instance.get(API_BaseURL + "/users/imagePostURL", {
    headers: {
      "x-auth-token": user?.token,
    },
  })

  await fetch(res.data.url, {
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: file,
  })

  const url = res.data.url.split("?")[0]
  return url
}

function displayError(httpError, setError, setIsLoading) {
  if (httpError.response?.data) setError(httpError.response.data)
  else setError("An unexpected error happened!")
  setIsLoading(false)
  setTimeout(() => {
    setError(undefined)
  }, 4000)
}

const deleteAccount = async setError => {
  const user = JSON.parse(localStorage.getItem("authUser"))

  instance
    .delete(API_BaseURL + "/users/" + user.username, {
      headers: {
        "x-auth-token": user?.token,
      },
    })
    .then(() => {
      localStorage.clear()
      window.location.href = "https://x-act.me"
    })
    .catch(err => {
      displayError(err, setError)
    })
}

const displayMessage = (setMessage, message) => {
  setMessage(message)

  setTimeout(() => {
    setMessage()
  }, 5000)
}

export {
  getUserByID,
  getMyAccount,
  getMyAccountSync,
  login,
  register,
  update,
  forgotPassword,
  changePassword,
  changeEmail,
  resetPassword,
  finalizePlanCheckout,
  getScans,
  uploadImage,
  addItem,
  activate,
  deactivateSubscription,
  deleteAccount,
  activateAccount,
  changeUsername,
}
