import React, { useEffect, useState } from "react"
import styled from "styled-components"

import DetailsHeader from "./DetailsHeader"
import DetailsBody from "./DetailsBody"
import { Link, useParams } from "react-router-dom"
import Loading from "pages/Helpers/Loading"
import { Colors } from "helpers/Colors"
import { getMyAccount, getUserByID } from "helpers/api"
import { Modal } from "reactstrap"
import QRCode from "qrcode.react"
import logo from "../../assets/images/logo-dark.png"
import { API_BaseURL } from "helpers/constants"
import { withTranslation } from "react-i18next"

function Details(props) {
  let { id } = useParams()
  const [mypage, setMyPage] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  const [copied, setCopied] = useState(false)

  const [userTheme, setUserTheme] = useState({
    userColor: Colors.primaryColor,
  })

  useEffect(() => {
    if (!id && localStorage.getItem("authUser")) {
      getMyAccount(setUser, setLoading, setUserTheme)
      setMyPage(true)
    } else {
      getUserByID(id, setUser, setLoading, setUserTheme)
    }
  }, [id, mypage])

  return (
    <Loading isLoading={loading}>
      <DetailsWrapper theme={userTheme}>
        {!mypage && (
          <div className="logo-header">
            <a onClick={() => (window.location.href = "https://x-act.me")}>
              <img src={logo} alt="logo x-act.me" />
            </a>
          </div>
        )}
        <DetailsContent theme={userTheme}>
          <DetailsHeader
            theme={userTheme}
            user={user}
            setPopupDisplayed={setModalOpen}
          />
          <DetailsBody t={props.t} theme={userTheme} user={user} />

          <div className="d-flex  flex-wrap gap-2 justify-content-center mt-5">
            {mypage ? (
              <Link to="/profile">
                <button type="button" className="btn btn-primary">
                  <i className="bx bxs-edit-alt font-size-16 align-middle me-2"></i>{" "}
                  {props.t("Edit")}
                </button>{" "}
              </Link>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  window.open(API_BaseURL + "/users/download/" + user.username)
                }}
              >
                <i className=" bx bx-download  font-size-16 align-middle me-2"></i>{" "}
                {props.t("Download")}
              </button>
            )}
            {(user?.tagID || id) && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setModalOpen(true)}
              >
                <i className="bx bxs-share-alt font-size-16 align-middle me-2"></i>{" "}
                {props.t("Share")}
              </button>
            )}
          </div>
        </DetailsContent>

        <Modal
          isOpen={modalOpen}
          toggle={() => {
            setModalOpen(false)
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Share Your Profile</h5>
            <button
              type="button"
              onClick={() => {
                setModalOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center my-4">
              <QRCode
                value={"https://app.x-act.me/details/" + (user?.tagID ?? id)}
              />
            </div>

            <a
              className={
                "mb-0 text-center d-block" + (copied ? " text-success" : "")
              }
              onClick={() => {
                navigator.clipboard.writeText(
                  "https://app.x-act.me/details/" + (user?.tagID ?? id)
                )
                setCopied(true)
              }}
            >
              {copied
                ? "successfully copied"
                : "Or click here to copy the link of the profile"}
            </a>
          </div>
        </Modal>
      </DetailsWrapper>
    </Loading>
  )
}

const DetailsContent = styled.div`
  align-self: center;
  padding: 15vh 5%;
  min-width: 60%;

  button {
    background: ${props => props.theme?.userColor ?? Colors.primaryColor};
    border-color: ${props => {
      return props.theme?.userColor ?? Colors.primaryColor
    }};

    &:hover {
      background: ${props => props.theme?.userColor ?? Colors.primaryColor};
      opacity: 0.9;
      transition: transform 0.3s ease;
    }

    &:focus {
      background: ${props => props.theme?.userColor ?? Colors.primaryColor};
      border: none;
    }
  }
`

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-header {
    position: absolute;
    top: 2vh;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 15rem;
      max-width: 40vw;
    }
  }

  .share-button {
    background: ${props => props.theme?.userColor ?? Colors.primaryColor};
    color: white;
    font-size: 1.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20vh;
    right: 25%;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }

    &:focus {
      background: ${props => props.theme?.userColor ?? Colors.primaryColor};
    }
  }

  @media (max-width: 1000px) {
    .share-button {
      font-size: 1.5rem;
      width: 2.5rem;
      height: 2.5rem;
      right: 15%;
    }
  }
`
export default withTranslation()(Details)
