import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux

import { Link, useParams } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import logo from "assets/images/logo-sm-light.png"

//Import config
import { activate } from "helpers/api"
import Loading from "pages/Helpers/Loading"

const Activation = () => {
  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      setError()
      setSuccess()
      activate(
        id,
        values.email,
        values.password,
        setError,
        setLoading,
        setSuccess
      )
      validation.resetForm()
    },
  })

  return (
    <React.Fragment>
      <Loading isLoading={loading}>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={12}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Aktivierung!</h5>
                          <p>
                            Melden Sie sich bitte mit Ihrem x-ACT.me Account an,
                            oder erstellen Sie einen neuen Account, damit sie
                            Ihren Tag aktivieren können.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="75"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        {success ? (
                          <Alert color="success">{success}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">E-Mail</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="E-Mail oder Username"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Passwort</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Passwort"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Anmelden
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Passwort vergessen?
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Noch keinen Account ?{" "}
                    <Link to="/register" className="fw-medium text-primary">
                      {" "}
                      Jetzt registrieren{" "}
                    </Link>{" "}
                  </p>
                  <p>© {new Date().getFullYear()} x-ACT.me</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Loading>
    </React.Fragment>
  )
}

export default Activation

Activation.propTypes = {
  history: PropTypes.object,
}
